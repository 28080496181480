import React, { useEffect, useState } from 'react';
import { getConfig } from './config';
import { Input, notification } from 'antd';
const { Search } = Input;

function SearchMembers({onSearchUpdate}) {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (message) => {
        api.info({
          message: message,
          placement: 'topRight',
        });
      };

    const getData = (value, _e, info) => {

        if (value.length<3) {

            openNotification("Search must be at least three letters");

        } else {
        var jsonData = {
            action: 'getMemberSearch',
            search: value,
        };

        console.log(jsonData);
    
        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
            if (responseData['status'] === 'SUCCESS') {
                const resultArray = responseData.message;
                //console.log(resultArray);
                onSearchUpdate(resultArray);

            } else {
                console.error('Data is not in expected format:', responseData.message);
            }
        })
        .catch(error => console.error('Fetching Member Data failed:', error));
    };
} // length check

return (
    <>
        {contextHolder}
        <div><Search
                placeholder="input search text (min 3 letters)"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={getData}
    /></div>
    </>
);

};

export default SearchMembers;