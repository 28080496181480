import React, { useState } from 'react';
import { notification, Input, Radio } from 'antd';
import CMDropdown from './dropdown';
import { Checkbox } from 'antd';
import { getConfig } from './config';
import SearchMembers from './searchMembers';

const { TextArea } = Input;

function MemberEdit() {

    const [currentMember, setCurrentMember] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    const [isMemberChoiceVisible, setIsMemberChoiceVisible] = useState(false);
    const [foundMembers, setFoundMembers] = useState([]);

    const openNotification = (message) => {
        api.info({
          message: message,
          placement: 'topRight',
        });
      };


    const handleMemberNameUpdate = (value) => {
        setCurrentMember(prevMember => {
            return { ...prevMember, memberName: value };
          });
    }

    const handleMemberAddressUpdate = (value) => {
        setCurrentMember(prevMember => {
            return { ...prevMember, memberAddress: value };
          });
    }

    const handleMemberEmailUpdate = (value) => {
        setCurrentMember(prevMember => {
            return { ...prevMember, memberEmail: value };
          });
    }

    const handleMemberPhoneUpdate = (value) => {
        setCurrentMember(prevMember => {
            return { ...prevMember, memberPhone: value };
          });
    }

    const handleMemberNotesUpdate = (event) => {
        const value = event.target.value;
        setCurrentMember(prevMember => {
            return { ...prevMember, memberNotes: value };
        });
    }

    const onChangeCheckbox = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setCurrentMember(prevMember => {
            return { ...prevMember, memberCurrent: e.target.checked };
          });
      };

      function MembersRadioGroup({ members, defaultValue, onChange }) {
        return (
          <Radio.Group defaultValue={defaultValue} buttonStyle="solid" onChange={onChange}>
            {members.map(member => (
              <Radio.Button key={member.id} value={member.id}>
                {member.memberName}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      }

      const handleSubmitButton = () => {
            var jsonData = {
                action: 'updateMember',
                member: currentMember
            };

            console.log(JSON.stringify(jsonData));
                
            fetch(getConfig('CM_API_URL'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            })
            .then(response => response.json()) 
            .then(responseData => {
                if (responseData['status'] === 'SUCCESS') {
                openNotification(`Membership Contact Details have been updated`);

                }
            })
            
        };

        const onMemberSearchUpdate = (foundMembers) => {
            console.log("In NewBooking:\n" + JSON.stringify(foundMembers));
            setFoundMembers(foundMembers);
            setIsMemberChoiceVisible(true);
        }
        
        const handleRadioChange = (e) => {
            console.log(`Selected member ID: ${e.target.value}`);
            const selectedMember = foundMembers.find(member => member.id === e.target.value);
            setCurrentMember(selectedMember);
            setIsMemberChoiceVisible(false);
        };

    return (
        <>
        {contextHolder}

        <div className="edit-booking-underlay" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}></div>
        <div className="edit-booking-container" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}>
            {foundMembers.length > 0 && (
                <MembersRadioGroup
                    members={foundMembers}
                    onChange={handleRadioChange}
                />
            )}
        </div>

        <h2 className="title-bar">Member Edit</h2>
        <div className="border-display">
        <div style={{ padding: 10 }}>
                          <SearchMembers
                                onSearchUpdate={onMemberSearchUpdate}
                          />      
        </div>
        </div>
        <div className="edit-member-container">
            <div className="edit-member-row">
                <div className="member-edit-label">Name:</div>
                <div className="member-edit-data"><Input value={currentMember.memberName} onChange={handleMemberNameUpdate} /></div>
            </div>
            <div className="edit-member-row">
                <div className="member-edit-label">Address:</div>
                <div className="member-edit-data"><Input value={currentMember.memberAddress} onChange={handleMemberAddressUpdate} /></div>
            </div>
            <div className="edit-member-row">
                <div className="member-edit-label">eMail:</div>
                <div className="member-edit-data"><Input value={currentMember.memberEmail} onChange={handleMemberEmailUpdate}/></div>
            </div>
            <div className="edit-member-row">
                <div className="member-edit-label">Phone:</div>
                <div className="member-edit-data"><Input value={currentMember.memberPhone} onChange={handleMemberPhoneUpdate}/></div>
            </div>
                <div className="edit-member-row"> 
                        <div className="member-edit-label">Level:</div>
                        <div className="member-edit-data" style={{ padding: 10 }}>
                            <CMDropdown 
                                dropdownCurrent={currentMember.memberLevel}
                                dropdownPrompt="Select Level"
                                dropdownItem="LEVEL"
                                onDropdownUpdate={(value) => setCurrentMember(prevMember => ({
                                    ...prevMember,
                                    memberLevel: value
                                }))}
                            />
                        </div>
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Notes:</div>
                    <div className="member-edit-data">
                        <TextArea 
                            rows={4} 
                            value={currentMember.memberNotes} 
                            onChange={handleMemberNotesUpdate}
                            
                        />
                    </div>
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Current:</div>
                    <div className="member-edit-data">
                        <Checkbox 
                            checked={currentMember.memberCurrent || false}
                            onChange={onChangeCheckbox}
                        >
                            Current Member
                        </Checkbox>
                    </div>
                    <div><button className="custom-antd-button" onClick={handleSubmitButton}>Update Member Details</button></div>
                </div>
            </div>
        </>
    );
};

export default MemberEdit;