import React, { useState, useEffect } from 'react';
import { ColorPicker, Input, Upload, message, notification } from 'antd';
import { getConfig } from './config';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

function getCookie(name) {
    let cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let parts = cookie.split('=');
        if (parts[0].trim() === name) {
            return parts[1];
        }
    }
    return "";
  }



function MyInfo ({onUpdateCurrentUser}) {

    const [userName, setUserName] = useState('');
    const [eMail, seteMail] = useState('');     
    const [userMobile, setUserMobile] = useState('');
    const [userCalendar, setUserCalendar] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [showWait, setShowWait] = useState(false);


    useEffect(() => {

            const getData = () => {

                setShowWait(true);

                var jsonData = {
                    action: 'getMyInfo',
                    userHash: getCookie('userHash')
                };
        
                console.log(jsonData);
            
                fetch(getConfig('CM_API_URL'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jsonData),
                })
                .then(response => response.json()) 
                .then(responseData => {
                    if (responseData['status'] === 'SUCCESS') {

                        console.log(responseData['message']);

                        seteMail(responseData['message'][0]['eMail']);
                        setUserMobile(responseData['message'][0]['userMobile']);
                        setUserName(responseData['message'][0]['userName']);
                        setImageUrl(responseData['message'][0]['avatar']);
                        setUserCalendar(responseData['message'][0]['userCalendar']);                       

                    }
                })
                .catch(error => console.error('Fetch from getMyInfo failed:', error))
                .finally(() => {
                    setShowWait(false);
                });
            };
        
            getData();
        }, []);

    const updateUserEmail = (event) => {
        seteMail(event.target.value);
    }

    const updateUserName = (event) => {
        setUserName(event.target.value);
    }

    const updateUserMobile = (event) => {
        setUserMobile(event.target.value);
    }

    const openNotification = (message) => {
        api.info({
          message: message,
          placement: 'topRight',
        });
      };



      const handleChange = (info) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };
      const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
      );

    const handleUpdate = () => {

        const hexString = typeof userCalendar === 'string' ? userCalendar : userCalendar?.toHexString();
        const userHash = getCookie('userHash');
        const userCalendarHex = hexString;

        var jsonData = {
            action: 'updateProfile',
            userHash: userHash,
            eMail: eMail,
            userMobile: userMobile,
            userName: userName,
            avatar: imageUrl,
            userCalendar: userCalendarHex,
        };

        const currentUserData = {
            eMail: eMail,
            userHash: userHash,
            userMobile: userMobile,
            userName: userName,
            avatar: imageUrl,
            userCalendar: userCalendarHex,
        }
    
        console.log(JSON.stringify(jsonData));
            
        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
            if (responseData['status'] === 'SUCCESS') {
              openNotification(`Your details been updated`);
              onUpdateCurrentUser(currentUserData)
            }
          });
        
    }

    const HexCase = () => {
        return (
            <>
            <div>Personal Calendar Colour: 
            <div>
              <ColorPicker
                format='hex'
                disabledAlpha
                value={userCalendar}
                onChange={setUserCalendar}
            /></div>
            </div>
            </>
        );
      };

      return (
        <>
        {contextHolder}
        <h2 className="title-bar">My information: {userName}</h2>
        { showWait && (
                        <>
                        <div className="wait-gif">Loading your information...
                        <div><img alt="Please Wait" src="/assets/wait.gif" /></div>
                        </div>
                    </>
        )}
        <div>Name:<Input value={userName} onChange={updateUserName}></Input></div>
        <div>Phone:<Input value={userMobile} onChange={updateUserMobile}></Input></div>
        <div>eMail:<Input value={eMail} onChange={updateUserEmail}></Input><span className="warning">Note: this is your login name as well, change with extreme caution!</span></div>
        <div className="edit-booking-column-container">
        <div className="edit-booking-column">Avatar:
        <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      </div>
      <div className="edit-booking-column">
      <HexCase
      />
      </div>
      </div>
        <div><button className="custom-antd-button" onClick={handleUpdate}>Update My Info</button></div>

        </>
    )
}

export default MyInfo;
