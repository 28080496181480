import React, { useState } from 'react';
import { notification, Input, Radio, Checkbox } from 'antd';
import CMDropdown from './dropdown';
import SearchMembers from './searchMembers';
import { getConfig } from './config';

const { TextArea } = Input;

function MemberEditAdmin() {
    const [currentMember, setCurrentMember] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [isMemberChoiceVisible, setIsMemberChoiceVisible] = useState(false);
    const [foundMembers, setFoundMembers] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [addNewButton, setAddNewButton] = useState(false);

    const openNotification = (message) => {
        api.info({
            message: message,
            placement: 'topRight',
        });
    };

    // Handlers for Input Changes
    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setCurrentMember(prevMember => ({
            ...prevMember,
            [fieldName]: value
        }));
    };

    // Handler for Checkbox Change
    const onChangeCheckbox = (e) => {
        setCurrentMember(prevMember => ({
            ...prevMember,
            memberCurrent: e.target.checked
        }));
    };

    // Insert and Update Member
    const handleInsertButton = () => {

        var goodToGo = true;

        console.log(currentMember);

  
        if (goodToGo) {

                var jsonData = {
                    action: 'insertMember',
                    member: currentMember
                };

                console.log(JSON.stringify(jsonData));


                    
                fetch(getConfig('CM_API_URL'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jsonData),
                })
                .then(response => response.json()) 
                .then(responseData => {
                    if (responseData['status'] === 'SUCCESS') {
                    openNotification(`New Member Contact Details have been added`);

                    }
                })
    
    } // goodtogo

    };

    const handleSubmitButton = () => {


        console.log(currentMember);
        
            var jsonData = {
                action: 'updateMember',
                member: currentMember
            };

            console.log(JSON.stringify(jsonData));
   
            fetch(getConfig('CM_API_URL'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            })
            .then(response => response.json()) 
            .then(responseData => {
                if (responseData['status'] === 'SUCCESS') {
                openNotification(`Membership Contact Details have been updated`);

                }
            })


    };

    const handleNewButton = () => {
        setCurrentMember({});
        setAddNew(true);
        setAddNewButton(true); // To toggle visibility based on state
        openNotification("Please enter the new member's details");
        // set a default for level
        setCurrentMember(prevMember => ({
            ...prevMember,
            memberLevel: 'Beginner'
        }));
    };

    const handleRadioChange = (e) => {
        console.log(`Selected member ID: ${e.target.value}`);
        const selectedMember = foundMembers.find(member => member.id === e.target.value);
        setCurrentMember(selectedMember);
        setIsMemberChoiceVisible(false);
        setAddNew(true);
        setAddNewButton(false); 
    };

    const onMemberSearchUpdate = (foundMembers) => {
        setFoundMembers(foundMembers);
        setIsMemberChoiceVisible(true);
        setAddNew(false); // Assuming to toggle form visibility
    };

    // Including the full JSX as originally provided
    return (
        <>
            {contextHolder}
            <div className="edit-booking-underlay" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}></div>
            <div className="edit-booking-container" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}>
                {/* Integration of MembersRadioGroup directly or using Radio.Group */}
                {foundMembers.length > 0 && (
                    <Radio.Group onChange={handleRadioChange} buttonStyle="solid">
                        {foundMembers.map(member => (
                            <Radio.Button key={member.id} value={member.id}>
                                {member.memberName}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                )}
            </div>

            <h2 className="title-bar">Member Edit (as Admin)</h2>
            { !addNew && (
                <div className="border-display">
                    <div style={{ padding: 10 }}>
                        <SearchMembers onSearchUpdate={onMemberSearchUpdate} />
                    </div>
                </div>
            )}
            <div className="edit-member-container">
                {/* Repeating for each member attribute with the correct handler */}
                <div className="edit-member-row">
                    <div className="member-edit-label">Name:</div>
                    <Input value={currentMember.memberName || ''} onChange={(e) => handleInputChange(e, 'memberName')} disabled={!addNew} />
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Address:</div>
                    <Input value={currentMember.memberAddress || ''} onChange={(e) => handleInputChange(e, 'memberAddress')} disabled={!addNew} />
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Email:</div>
                    <Input value={currentMember.memberEmail || ''} onChange={(e) => handleInputChange(e, 'memberEmail')} disabled={!addNew} />
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Phone:</div>
                    <Input value={currentMember.memberPhone || ''} onChange={(e) => handleInputChange(e, 'memberPhone')} disabled={!addNew} />
                </div>
                <div className="edit-member-row"> 
                        <div className="member-edit-label">Level:</div>
                        <div className="member-edit-data" style={{ padding: 10 }}>
                            <CMDropdown 
                                dropdownCurrent={currentMember.memberLevel}
                                dropdownPrompt="Select Level"
                                dropdownItem="LEVEL"
                                onDropdownUpdate={(value) => setCurrentMember(prevMember => ({
                                    ...prevMember,
                                    memberLevel: value
                                }))}
                            />
                        </div>
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Notes:</div>
                    <TextArea rows={4} value={currentMember.memberNotes || ''} onChange={(e) => handleInputChange(e, 'memberNotes')} disabled={!addNew} />
                </div>
                
                <div className="edit-member-row">
                    <div className="member-edit-label">Current:</div>
                    <Checkbox checked={currentMember.memberCurrent || false} onChange={onChangeCheckbox} disabled={!addNew}>Current Member</Checkbox>
                </div>
                
                {addNewButton && (
                    <button className="custom-antd-button" onClick={handleInsertButton}>Add as New Member</button>
                )}
                {!addNewButton && addNew && (
                    <button className="custom-antd-button" onClick={handleSubmitButton}>Update Member Details</button>
                )}
                <button className="custom-antd-button" onClick={handleNewButton} style={{ display: addNew ? 'none' : 'block' }}>Create a New Member</button>
            </div>
        </>
    );
}

export default MemberEditAdmin;
