import React, { useState, useEffect } from 'react';
import './App.css';
import { getConfig } from './components/config';
import Login from "./components/login";
import Register from "./components/register";
import ForgotPassword from "./components/forgotpassword";
import ChangePassword from "./components/changePassword";
import DayPicker from './components/dayPicker';
import { message, Space } from 'antd';
import { Layout } from 'antd';
import Calendar from './components/calendar';
import CalendarAll from './components/calendarAll';
import MyInfo from './components/myInfo';
import MemberEdit from './components/memberEdit';
import dayjs from 'dayjs';
import DropdownMenu from './components/navbar';
import MemberEditAdmin from './components/memberEditAdmin';
import UserEditAdmin from './components/userEditAdmin';

const { Content } = Layout;

function getCookie(name) {
  let cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let parts = cookie.split('=');
      if (parts[0].trim() === name) {
          return parts[1];
      }
  }
  return "";
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}


function App() {
  const [currentActivity, setCurrentActivity] = useState("LOGIN");
  const [currentUser, setCurrentUser] = useState([]);
  const [userHash, setUserHash] = useState("");
  const [dateRangeStart, setDateRangeStart] = useState("");
  const [dateRangeEnd, setDateRangeEnd] = useState("");

  useEffect(() => {
    const storedUserHash = getCookie("userHash");
    if (storedUserHash) {
      setUserHash(storedUserHash);
      setCurrentActivity("SORTED");
    }
  }, []);

  const handleLogOut = () => {
    setCurrentActivity("LOGIN");
    setUserHash("");
    setCookie("userHash", "", 1);
    setCurrentUser([]);
  };

  const handleMyProfile = () => {
    setCurrentActivity("MYPROFILE");
  }

  const handleMyBookings = () => {
    setCurrentActivity("SORTED");
  }

  const handleAllCalendars = () => {
    setCurrentActivity("ALLCALENDARS");
  }

  const handleMembers = () => {
    setCurrentActivity("MEMBERS");
  }

  const handleUsersAdmin = () => {
    setCurrentActivity("USERSADMIN");

  }

  const handleMembersAdmin = () => {
    setCurrentActivity("MEMBERSADMIN");
  }


  const handleDayPickerChange = (value) => {
      const startDate = value.format('YYYY-MM-DD');
      const dateStartDayjs = dayjs(startDate);
      const endDate = dateStartDayjs.add(7, 'day').format('YYYY-MM-DD');
  
      setDateRangeStart(startDate);
      setDateRangeEnd(endDate);
      message.info(`Start: ${startDate}, End: ${endDate}`);

  };

  // callback from login
  const onUserUpdate = (user) => {
    setCurrentUser(user);
  }

  // callback from myProfile
  const onUpdateCurrentUser = (user) => {
    setCurrentUser(user);
  }

  const onMenuChoice = (value) => {
      if (value === 1 && currentActivity !== "LOGIN") {
            handleMyBookings();
      }

      if (value === 2 && currentActivity !== "LOGIN") {
            handleMyProfile();
      }

      if (value === 3 && currentActivity !== "LOGIN") {
            handleAllCalendars();
      }      

      if (value === 4 && currentActivity !== "LOGIN") {
            handleMembers();
      }

      if (value === 5 && currentActivity !== "LOGIN") {
        handleUsersAdmin();
      }

      if (value === 6 && currentActivity !== "LOGIN") {
        handleMembersAdmin();
      }

      if (value === 7) {
            handleLogOut();
      }
  }


  return (
    <>
      <header className="title-bar">

        <img
          src="../assets/Image_001.jpg"
          alt="CodeMonkey Design Ltd"
          className="logo"
        />
        <h3 className="app-title">Dance Studio Diary</h3>
        {(currentActivity === "REGISTER" || currentActivity === "FORGOTPASSWORD") && (
          <button id="logOut" className="custom-antd-button-menu" onClick={handleLogOut}>
            Back to front page
          </button>
        )}
        {currentUser['avatar'] && (
          <div style={{ textAlign:'right' }}>
            <img
              src={currentUser['avatar']}
              alt={currentUser['userName']}
              className="logo"
              style={{ border: `5px solid ${currentUser['userCalendar']}`}}
            />
            <div style={{ fontSize: 10 }}>{currentUser['userName']}</div>
          </div>
        )}
      </header>
      <Layout>
        <Content>
        <DropdownMenu currentUser={currentUser} onMenuChoice={onMenuChoice}/>
          {currentActivity === "SORTED" && (
            <div className='main-display'>
              <DayPicker onValueChange={handleDayPickerChange} />
              {dateRangeStart && (
                <>
                  <Space />
                  <Calendar 
                    fromDate={dateRangeStart}
                    toDate={dateRangeEnd}
                    currentUser={currentUser}
                  />
                </>
              )}
            </div>
          )}
          {currentActivity === "ALLCALENDARS" && (
            <div className='main-display'>
              <DayPicker onValueChange={handleDayPickerChange} />
              {dateRangeStart && (
                <>
                  <Space />
                  <CalendarAll 
                    fromDate={dateRangeStart}
                    toDate={dateRangeEnd}
                  />
                </>
              )}
            </div>
          )}
          {currentActivity === "MYPROFILE" && (
            <div className="main-display">
              <MyInfo
                onUpdateCurrentUser={onUpdateCurrentUser}
              />
            </div>
          )}
          {currentActivity === "MEMBERS" && (
            <div className="main-display">
              <MemberEdit />
            </div>
          )}
          {currentActivity === "MEMBERSADMIN" && (
            <div className="main-display">
              <MemberEditAdmin />
            </div>
          )}
          {currentActivity === "USERSADMIN" && (
            <div className="main-display">
              <UserEditAdmin />
            </div>
          )}
        </Content>
      </Layout>
      <div className="debug-label">
        The API is at <span className='debug-info'>{getConfig('CM_API_URL')}</span> Activity: <span className='debug-info'>{currentActivity}</span>  userHash: <span className='debug-info'>{userHash}</span>
      </div>
      {currentActivity === "LOGIN" && (
        <Login
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          setUserHash={setUserHash}
          loginPromptText="User Login"
          onUserUpdate={onUserUpdate}
        />
      )}
      {currentActivity === "LOGINAFTERFORGOTFAIL" && (
        <Login
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          setUserHash={setUserHash}
          loginPromptText="That wasn't a proper eMail"
        />
      )}
      {currentActivity === "REGISTER" && (
        <Register
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
        />
      )}
      {currentActivity === "FORGOTPASSWORD" && (
        <ForgotPassword
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          setUserHash={setUserHash}
        />
      )}
      {currentActivity === "AWAITINGAUTH" && (
        <div className="login-box">
          We have sent you an email to confirm your details. Please check your inbox
        </div>
      )}
      {currentActivity === "PASSWORDRESETAUTHCOMPLETE" && (
        <ChangePassword
          currentActivity={currentActivity}
          setCurrentActivity={setCurrentActivity}
          setUserHash={setUserHash}
        />
      )}
      <div className='bottom-label'>
      <img alt="cm-logo" src="../assets/Image_005.png" height="40" width="40" ></img>&nbsp;&copy; 2024 CodeMonkey Design Ltd. | 20 Clifton Ave, Plymouth, UK, PL7 4BJ | Tel: 07976 802123 | eMail: <a href="mailto:support@codemonkey.design">support@codemonkey.design</a>
      </div>
    </>
  );
  
}

export default App;
