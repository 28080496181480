import React, { useState } from 'react';
import { ColorPicker, Input, Upload, message, notification, Radio } from 'antd';
import { getConfig } from './config';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import CMDropdown from './dropdown';
import SearchUsers from './searchUsers';

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
  
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

function UserEditAdmin() {
    const [currentUser, setCurrentUser] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [isUserChoiceVisible, setIsUserChoiceVisible] = useState(false);
    const [foundUsers, setFoundUsers] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [addNewButton, setAddNewButton] = useState(false);
    const [loading, setLoading] = useState(false);


    const openNotification = (message) => {
        api.info({
            message: message,
            placement: 'topRight',
        });
    };

    // Handlers for Input Changes
    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        setCurrentUser(prevUser => ({
            ...prevUser,
            [fieldName]: value
        }));
    };

    const handleColorChange = (value, fieldName) => {
        const hexString = typeof value === 'string' ? value : value?.toHexString();
        console.log("Colour is " + hexString);
        const userCalendarHex = hexString;
        setCurrentUser(prevUser => ({
            ...prevUser,
            [fieldName]: userCalendarHex
        }));
    };

    // Insert and Update User
    // const handleInsertButton = () => {

    //     var goodToGo = false;

    //     console.log(currentUser);

  
    //     if (goodToGo) {

    //             var jsonData = {
    //                 action: 'insertUser',
    //                 user: currentUser
    //             };

    //             console.log(JSON.stringify(jsonData));


                    
    //             fetch(getConfig('CM_API_URL'), {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(jsonData),
    //             })
    //             .then(response => response.json()) 
    //             .then(responseData => {
    //                 if (responseData['status'] === 'SUCCESS') {
    //                 openNotification(`New User Contact Details have been added`);

    //                 }
    //             })
    
    // } // goodtogo

    // };

    const handleSubmitButton = () => {
        
            var jsonData = {
                action: 'updateUser',
                user: currentUser
            };

            console.log(JSON.stringify(jsonData));
   
            fetch(getConfig('CM_API_URL'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            })
            .then(response => response.json()) 
            .then(responseData => {
                if (responseData['status'] === 'SUCCESS') {
                openNotification(`User Details have been updated`);

                }
            })


    };

    const handleDeleteButton = () => {
    
        var jsonData = {
            action: 'deleteUser',
            user: currentUser
        };

        console.log(JSON.stringify(jsonData));

        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
            if (responseData['status'] === 'SUCCESS') {
            openNotification(`User has been deleted`);
            setCurrentUser([]);

            }
        })


    }

    // const handleNewButton = () => {
    //     setCurrentUser({});
    //     setAddNew(true);
    //     setAddNewButton(true); // To toggle visibility based on state
    //     openNotification("Please enter the new user's details");
    //     // set a default for level
    //     setCurrentUser(prevUser => ({
    //         ...prevUser,
    //         userLevel: 'Beginner'
    //     }));
    // };

    const handleRadioChange = (e) => {
        console.log(`Selected user ID: ${e.target.value}`);
        const selectedUser = foundUsers.find(user => user.id === e.target.value);
        setCurrentUser(selectedUser);
        setIsUserChoiceVisible(false);
        setAddNew(true);
        setAddNewButton(false); 
    };

    const onUserSearchUpdate = (foundUsers) => {
        setFoundUsers(foundUsers);
        setIsUserChoiceVisible(true);
        setAddNew(false); // Assuming to toggle form visibility
    };

    const handleAvatarChange = (info) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, (url) => {
            setLoading(false);
            setCurrentUser(prevUser => ({
                ...prevUser,
                avatar: url
            }));
          });
        }
      };
      const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
      );

      const HexCase = () => {
        return (

            <ColorPicker
                format="hex"
                disabledAlpha
                value={currentUser.userCalendar || ''} 
                onChange={(value) => handleColorChange(value, 'userCalendar')}
/>
        );
      };


    return (
        <>
            {contextHolder}
            <div className="edit-booking-underlay" style={{display: isUserChoiceVisible ? 'block' : 'none'}}></div>
            <div className="edit-booking-container" style={{display: isUserChoiceVisible ? 'block' : 'none'}}>
                {/* Integration of UsersRadioGroup directly or using Radio.Group */}
                {foundUsers.length > 0 && (
                    <Radio.Group onChange={handleRadioChange} buttonStyle="solid">
                        {foundUsers.map(user => (
                            <Radio.Button key={user.id} value={user.id}>
                                {user.userName}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                )}
            </div>

            <h2 className="title-bar">User Edit (as Admin)</h2>
            { !addNew && (
                <div className="border-display">
                    <div style={{ padding: 10 }}>
                        <SearchUsers onSearchUpdate={onUserSearchUpdate} />
                    </div>
                </div>
            )}
            <div className="member-edit-container">
                {/* Repeating for each user attribute with the correct handler */}
                <div className="edit-member-row">
                    <div className="member-edit-label">Name:</div>
                    <Input value={currentUser.userName || ''} onChange={(e) => handleInputChange(e, 'userName')} disabled={!addNew} />
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">eMail/Login:</div>
                    <Input value={currentUser.eMail || ''} onChange={(e) => handleInputChange(e, 'eMail')} disabled={!addNew} />
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Phone:</div>
                    <Input value={currentUser.userMobile || ''} onChange={(e) => handleInputChange(e, 'userMobile')} disabled={!addNew} />
                </div>
                <div className="edit-member-row"> 
                        <div className="member-edit-label">Privilege:</div>
                        <div className="member-edit-data" style={{ padding: 10 }}>
                            <CMDropdown 
                                dropdownCurrent={currentUser.userPrivilege}
                                dropdownPrompt="Select User Privilege"
                                dropdownItem="PRIVILEGE"
                                onDropdownUpdate={(value) => setCurrentUser(prevUser => ({
                                    ...prevUser,
                                    userPrivilege: value
                                }))}
                            />
                        </div>
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label">Calendar Colour:</div>
                    <div className="member-edit-data"><HexCase/></div>
                </div>

                <div className="edit-member-row">
                    <div className="member-edit-label">Avatar:</div>
                    <div className="member-edit-data" style={{ padding:20 }}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        beforeUpload={beforeUpload}
                        onChange={handleAvatarChange}
                    >
                        {currentUser.avatar ? (
                        <img
                            src={currentUser.avatar}
                            alt="avatar"
                            style={{
                            width: '100%',
                            }}
                        />
                        ) : (
                        uploadButton
                        )}
                    </Upload>    
                    </div>
                </div>
                <div className="edit-member-row">
                    <div className="member-edit-label"> </div>
                    <div className="member-edit-data"> 
                {/* {addNewButton && (
                    <button className="custom-antd-button" onClick={handleInsertButton}>Add as New User</button>
                )} */}
                {!addNewButton && addNew && (
                    <>
                    <button className="custom-antd-button" onClick={handleSubmitButton}>Update User Details</button>
                    <button className="custom-antd-button-red" onClick={handleDeleteButton}>Delete this User</button>
                    </>
                )}
                {/* <button className="custom-antd-button" onClick={handleNewButton} style={{ display: addNew ? 'none' : 'block' }}>Create a New User</button> */}
                </div>
                </div>
            </div>
        </>
    );
}

export default UserEditAdmin;
