import React, { useEffect, useState } from 'react';
import { DatePicker, notification, Input } from 'antd';
import { getConfig } from './config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CMDropdown from './dropdown';

function extractTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  
  function extractDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function convertDateTimeFormat(dateTimeString) {
    const parts = dateTimeString.split(' ');
    const date = parts[0];
    const time = parts[1];

    // console.log("startwith: " + dateTimeString + "\ndate: " + date + "\ntime: " + time);

    // Split the date into day, month, and year
    const dateParts = date.split('/');
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

        const formattedDateTime = `${year}-${month}-${day} ${time}:00`;

    return formattedDateTime;
}

function EditBooking(props) {
    // Initialize state with blank values
    const [eventDate, setEventDate] = useState('');
    const [originalDate, setOriginalDate] = useState('');
    const [eventWith, setEventWith] = useState('');     
    const [eventWithEmail, setEventWithEmail] = useState('');
    const [eventWithPhone, setEventWithPhone] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [api, contextHolder] = notification.useNotification();

    dayjs.extend(customParseFormat);
     
    // Update state when props.event changes
    useEffect(() => {
        if (props.event) {
            setEventDate(props.event.eventDate || '');
            setOriginalDate(props.event.eventDate || '');
            setEventWith(props.event.eventWith || '');
            setEventWithEmail(props.event.eventWithEmail || '');
            setEventWithPhone(props.event.eventWithPhone || '');
            setEventTitle(props.event.eventTitle || '');
        }
    }, [props.event]); // Only re-run the effect if props.event changes

    const handleEventDateChange = (value, dateString) => {
        setEventDate(dateString);
    }
    
    const handleEventWithChange = (event) => {
        setEventWith(event.target.value);
    }

    const handleEventWithEmailChange = (event) => {
        setEventWithEmail(event.target.value);
    }

    const handleEventWithPhoneChange = (event) => {
        setEventWithPhone(event.target.value);
    }

    const onDateTimeOk = () => {
        console.log("Date is " + eventDate)
    }

    const openNotification = (message) => {
        api.info({
          message: message,
          placement: 'topRight',
        });
      };

    const handleCancelEvent = () => {
        openNotification(`Changes cancelled`);
        const updatedEvent = { ...props.event, ...{ eventDate, eventWith, eventWithEmail, eventWithPhone } };
        props.onUpdate(updatedEvent);
    }

    const handleUpdateEvent = () => {

        var convertedEventDate=convertDateTimeFormat(eventDate);

        var jsonData = {
            action: 'updateBooking',
            bookingDate: convertedEventDate,
            bookingWith: eventWith,
            bookingWithEmail: eventWithEmail,
            bookingWithPhone: eventWithPhone,
            bookingTitle: eventTitle,
            originalDate: originalDate
        };
    
        console.log(JSON.stringify(jsonData));
            
        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
            if (responseData['status'] === 'SUCCESS') {
              openNotification(`Your booking has been updated`);
              const updatedEvent = { ...props.event, ...{ eventDate, eventWith, eventWithEmail, eventWithPhone, eventTitle } };
              props.onUpdate(updatedEvent);
            }
          })
        };

        const handleDropdownUpdate = (newTitle) => {      
            setEventTitle(newTitle);
        }

        const handleDeleteEvent = () => {

        var jsonData = {
            action: 'deleteBooking',
            bookingDate: eventDate,
        };
    
        console.log(JSON.stringify(jsonData));
            
        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
            if (responseData['status'] === 'SUCCESS') {
              openNotification(`Your booking has been deleted`);
              const updatedEvent = { ...props.event, ...{ eventDate, eventWith, eventWithEmail, eventWithPhone, eventTitle } };
              props.onUpdate(updatedEvent);
            }
          })



        }

 
    return (
        <>
        
        {contextHolder}
        <div className="main-title">Edit Booking {eventTitle} {extractDate(eventDate)} {extractTime(eventDate)} </div>
        <div><DatePicker format="DD/MM/YYYY HH:mm"
                    showTime={{
                        defaultValue: dayjs('10:00:00', 'HH:mm'),
                    }} 
                    minuteStep={15}
                    defaultValue={eventDate} 
                    onChange={handleEventDateChange} 
                    onOk={onDateTimeOk}
                    placeholder="Select new date..." /></div>
        <div style={{ padding: 10 }}><CMDropdown dropdownPrompt="Select Booking Type" dropdownCurrent={eventTitle} dropdownItem="BOOKING" onDropdownUpdate={handleDropdownUpdate}></CMDropdown></div>
        
        <div className="edit-member-container">
            <div className="edit-member-row">
                <div className="member-edit-label">With:</div>
                <div className="member-edit-data"><Input value={eventWith} onChange={handleEventWithChange} prompt="Booking with..." /></div>
            </div>
        <div className="edit-member-row">
            <div className="member-edit-label">Email:</div>
            <div className="member-edit-data"><Input value={eventWithEmail} onChange={handleEventWithEmailChange} prompt="Email" /></div>
        </div>
        <div className="edit-member-row">
                <div className="member-edit-label">Phone:</div>
                <div className="member-edit-data"><Input value={eventWithPhone} onChange={handleEventWithPhoneChange} prompt="Phone Number" /></div>
        </div>
        </div>
        <div><button className="custom-antd-button" onClick={()=>handleUpdateEvent()}>Update this booking</button></div>
        <div><button className="custom-antd-button-red" onClick={()=>handleDeleteEvent()}>Delete Booking</button></div>
        <div><button className="custom-antd-button" onClick={()=>handleCancelEvent()}>Cancel Update</button></div>
        
        
        </>

    )
}

export default EditBooking;