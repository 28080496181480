import React, { useEffect, useState } from 'react';
import { DatePicker, notification, Input, Radio } from 'antd';
import { getConfig } from './config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CMDropdown from './dropdown';
import SearchMembers from './searchMembers';

  function getCookie(name) {
    let cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let parts = cookie.split('=');
        if (parts[0].trim() === name) {
            return parts[1];
        }
    }
    return "";
  }



function NewBooking(props) {
    // Initialize state with blank values
    const [eventDate, setEventDate] = useState(props.eventDate || '');
    const [eventTitle, setEventTitle] = useState('');
    const [eventWith, setEventWith] = useState('');     
    const [eventWithEmail, setEventWithEmail] = useState('');
    const [eventWithPhone, setEventWithPhone] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const storedUserHash = getCookie("userHash");
    const [currentMember, setCurrentMember] = useState([]);
    const [isMemberChoiceVisible, setIsMemberChoiceVisible] = useState(false);
    const [foundMembers, setFoundMembers] = useState([]);

    dayjs.extend(customParseFormat);

    function MembersRadioGroup({ members, defaultValue, onChange }) {
        return (
          <Radio.Group defaultValue={defaultValue} buttonStyle="solid" onChange={onChange}>
            {members.map(member => (
              <Radio.Button key={member.id} value={member.id}>
                {member.memberName}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      }

    useEffect(() => {
        setEventDate(props.eventDate || '');
    }, [props.eventDate]);

    const handleEventDateChange = (value, dateString) => {
        setEventDate(dateString);
    }
    
    const handleEventWithChange = (event) => {
        setEventWith(event.target.value);
    }

    const handleEventWithEmailChange = (event) => {
        setEventWithEmail(event.target.value);
    }

    const handleEventWithPhoneChange = (event) => {
        setEventWithPhone(event.target.value);
    }

    const onDateTimeOk = () => {
        console.log("Date is " + eventDate)
    }

    const openNotification = (message) => {
        api.info({
          message: message,
          placement: 'topRight',
        });
      };

    const handleCancelEvent = () => {
        openNotification(`Changes cancelled`);
        const updatedEvent = { ...props.event, ...{ eventDate, eventWith, eventWithEmail, eventWithPhone } };
        props.onUpdate(updatedEvent);
    }

    const handleUpdateEvent = () => {

        var goodToGo = true;

        // begin validation
        if (eventTitle.length===0) {
            openNotification("No Booking Type Selected");
            goodToGo=false;
        }

        if (eventWith.length===0) {
            openNotification("You have not entered who this class is with");
            goodToGo=false;
        }

        if (eventWithPhone.length===0  || eventWithEmail.length===0) {
            openNotification("You have not entered any contact details.");
            goodToGo=false;
        }


        // end validation

        var jsonData = {
            action: 'insertBooking',
            bookingDate: eventDate,
            bookingWith: eventWith,
            bookingWithEmail: eventWithEmail,
            bookingWithPhone: eventWithPhone,
            bookingUser: storedUserHash,
            bookingTitle: eventTitle
        };
    
        console.log(JSON.stringify(jsonData));

        if (goodToGo) {
            
                fetch(getConfig('CM_API_URL'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jsonData),
                })
                .then(response => response.json()) 
                .then(responseData => {
                    if (responseData['status'] === 'SUCCESS') {
                    openNotification(`Your booking has been created`);
                    const updatedEvent = { ...props.event, ...{ eventDate, eventWith, eventWithEmail, eventWithPhone } };
                    props.onUpdate(updatedEvent);
                    }
                })

        } // goodToGo

        };

        const handleDropdownUpdate = (newTitle) => {      
            setEventTitle(newTitle);
        }

        const onMemberSearchUpdate = (foundMembers) => {
            console.log("In NewBooking:\n" + JSON.stringify(foundMembers));
            setFoundMembers(foundMembers);
            setIsMemberChoiceVisible(true);
        }
        
        const handleRadioChange = (e) => {
            console.log(`Selected member ID: ${e.target.value}`);
            const selectedMember = foundMembers.find(member => member.id === e.target.value);
            setCurrentMember(selectedMember);
            setEventWith(selectedMember.memberName);
            setEventWithEmail(selectedMember.memberEmail);
            setEventWithPhone(selectedMember.memberPhone);
            setIsMemberChoiceVisible(false);
        };
 
    return (
        <>
        
        {contextHolder}

        <div className="edit-booking-underlay" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}></div>
        <div className="edit-booking-container" style={{display: isMemberChoiceVisible ? 'block' : 'none'}}>
            {foundMembers.length > 0 && (
                <MembersRadioGroup
                    members={foundMembers}
                    onChange={handleRadioChange}
                />
            )}
        </div>

        <div className="main-title">New Booking {eventDate}</div>
        <div style={{ padding: 10 }}><DatePicker format="DD/MM/YYYY HH:mm"
                    showTime={{
                        defaultValue: dayjs('10:00:00', 'HH:mm'),
                    }} 
                    minuteStep={15}
                    defaultPickerValue={eventDate} 
                    onChange={handleEventDateChange} 
                    onOk={onDateTimeOk}
                    placeholder="Select new date..." />
        </div>
        <div style={{ padding: 10 }}><CMDropdown dropdownPrompt="Select Booking Type" 
                                     dropdownCurrent={eventTitle} 
                                     dropdownItem="BOOKING" 
                                     onDropdownUpdate={handleDropdownUpdate}>
                          </CMDropdown>
        </div>
        <div style={{ padding: 10 }}>
                          <SearchMembers
                                onSearchUpdate={onMemberSearchUpdate}
                          />      
        </div>
        <div className="edit-member-container">
            <div className="edit-member-row">
                <div className="member-edit-label">With:</div>
                <div className="member-edit-data"><Input value={eventWith} onChange={handleEventWithChange} prompt="Booking with..." /></div>
                </div>
            <div className="edit-member-row">
                <div className="member-edit-label">Email:</div>
                <div className="member-edit-data"><Input value={eventWithEmail} onChange={handleEventWithEmailChange} prompt="Email" /></div>
            </div>
            <div className="edit-member-row">
                <div className="member-edit-label">Phone:</div>
                <div className="member-edit-data"><Input value={eventWithPhone} onChange={handleEventWithPhoneChange} prompt="Phone Number" /></div>
            </div>
        </div>
        <div><button className="custom-antd-button" onClick={()=>handleUpdateEvent()}>Create Booking</button></div>
        <div><button className="custom-antd-button" onClick={()=>handleCancelEvent()}>Cancel</button></div>
        
        
        </>

    )
}

export default NewBooking;