import React, { useState, useEffect } from 'react';
import { getConfig } from './config';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, message, Space } from 'antd';

function CMDropdown({ dropdownPrompt, dropdownItem, dropdownCurrent, onDropdownUpdate}) {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const getData = () => {
            var jsonData = {
                action: 'getConfigData',
                item: dropdownItem
            };

            console.log(jsonData);
        
            fetch(getConfig('CM_API_URL'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            })
            .then(response => response.json()) 
            .then(responseData => {
                
                if (responseData['status'] === 'SUCCESS') {
                    const resultArray=JSON.parse(responseData.message);
                    const transformedItems = resultArray.map(item => ({
                        label: item.label,
                        key: item.key,
                    }));
                    setItems(transformedItems);
                } else {
                    console.error('Data is not in expected format:', responseData.message);
                }
            })
            .catch(error => console.error('Fetching data failed:', error));
        };
    


        getData();
    }, []); // Ensures this effect runs only once on component mount

    const onClick = ({ key }) => {
        onDropdownUpdate(key);
      };

    return (
        <Dropdown
            menu={{
            items,
            onClick,
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
            <Space>
                {dropdownCurrent ? dropdownCurrent : dropdownPrompt}
                <DownOutlined />
            </Space>
            </a>
        </Dropdown>
    );
}

export default CMDropdown;
