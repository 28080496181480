import React, { useState, useEffect } from 'react';
import { getConfig } from './config';
import { Popover, message } from 'antd';
import EditBooking from './editBooking';
import NewBooking from './newBooking';

function getCookie(name) {
    let cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let parts = cookie.split('=');
        if (parts[0].trim() === name) {
            return parts[1];
        }
    }
    return "";
  }

  function getNextSevenDays(startDateStr) {
    const startDate = new Date(startDateStr);
    let dates = [];
  
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
  
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is 0-indexed
      const year = currentDate.getFullYear();
      const dayOfWeek = currentDate.toLocaleDateString('en-gb', { weekday: 'short' });
  
      dates.push(`${dayOfWeek} ${day}/${month}/${year}`);
    }
  
    return dates;
  }

  function transformDateBack(dateStr) {
        const datePart = dateStr.split(' ')[1];
        const parts = datePart.split('/');
        const transformedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return transformedDate;
    }

  function Calendar({fromDate, toDate, currentUser}) {
    const [events, setEvents] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [isBookingVisible, setIsBookingVisible] = useState(false);
    const [isNewBookingVisible, setIsNewBookingVisible] = useState(false);
    const [editEvent, setEditEvent] = useState([]);
    const [newEvent, setNewEvent] = useState('');
    const [showWait, setShowWait] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(0);


        useEffect(() => {
            
            setShowWait(true);
        
            const getData = () => {
                var jsonData = {
                    action: 'getEvents',
                    userHash: getCookie('userHash'),
                    from: fromDate + " 00:00:00",
                    to: toDate + " 23:59:59"
                };
        
                console.log(jsonData);
            
                fetch(getConfig('CM_API_URL'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jsonData),
                })
                .then(response => response.json()) 
                .then(responseData => {
                    if (responseData['status'] === 'SUCCESS') {
                        setEvents(responseData['message']);
                    }
                })
                .catch(error => console.error('Fetch from getEvents failed:', error))
                .finally(() => {
                    setShowWait(false);
                });
            };
        
            getData();
    }, [fromDate, toDate, triggerFetch]); 
        



    const next7 = getNextSevenDays(fromDate);

    const handleEventSelection = (selectDate, selectedEvent) => {

        if (selectedEvent===undefined) {

            messageApi.info("Creating Event for " + selectDate);
            setIsNewBookingVisible(!isNewBookingVisible);
            setNewEvent(selectDate);

        } else {
            setIsBookingVisible(!isBookingVisible);
            setEditEvent(selectedEvent);
        }    
    }

    const handleNewEventUpdate = (updatedEvent) => {
        const updatedEvents = events.map(event => {
          if (event.id === updatedEvent.id) {
            return updatedEvent;
          }
          return event;
        });
        setEvents(updatedEvents); // This is likely the correct call
        setIsNewBookingVisible(!isNewBookingVisible);
        setTriggerFetch(triggerFetch + 1); // rerender calendar
    };

    const handleEventUpdate = (updatedEvent) => {
        const updatedEvents = events.map(event => {
          if (event.id === updatedEvent.id) {
            return updatedEvent;
          }
          return event;
        });
        setEditEvent(updatedEvents);
        setIsBookingVisible(!isBookingVisible);
        setTriggerFetch(triggerFetch + 1); // rerender calendar
      };

    const renderTimeSlots = (thisDate, events) => {
        const slots = [];
        const startTime = 8; // 8 AM
        const endTime = 22; // 10 PM

        console.log(events);

        for (let hour = startTime; hour < endTime; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                const currentDate = transformDateBack(thisDate) + ' ' + time + ':00';
    
                const matchingEvent = events.find(event => event.eventDate === currentDate);
    
                slots.push(
                    <div key={time} className="time-slot">

                        <div className="select-event" onClick={() => handleEventSelection(currentDate, matchingEvent)}>
                            <span className="event-time">{time}</span>
                           
                            { matchingEvent && (
                                <span className="event-title"  style={{ backgroundColor:currentUser['userCalendar'] }}>
                                    <Popover
                                        title={matchingEvent.eventTitle}
                                        content={(
                                            <div className="popover-content">
                                            <p>{matchingEvent.eventWith}<br/>
                                            {matchingEvent.eventWithEmail}<br/>
                                            {matchingEvent.eventWithPhone}</p>
                                            </div>
                                        )}
                                        >&nbsp;{matchingEvent.eventTitle}
                                    </Popover>
                                    </span>
                            )}
                        </div>
                    </div>
                
                );
            }
        }
        return slots;
    };
    
       

    return (
        <>
        { showWait && (
            <>
                <div className="wait-gif">Loading Calendar...
                <div><img alt="Please Wait" src="/assets/wait.gif" /></div>
                </div>
            </>
        ) }
        <div className="edit-booking-underlay" style={{display: isBookingVisible ? 'block' : 'none'}}></div>
        <div className="edit-booking-container" style={{display: isBookingVisible ? 'block' : 'none'}}><EditBooking event={editEvent} onUpdate={handleEventUpdate}/></div>
        <div className="edit-booking-underlay" style={{display: isNewBookingVisible ? 'block' : 'none'}}></div>
        <div className="edit-booking-container" style={{display: isNewBookingVisible ? 'block' : 'none'}}><NewBooking eventDate={newEvent} onUpdate={handleNewEventUpdate}/></div>
        <div className="week-calendar">
            <div className="calendar-body">
                {next7.map((day, index) => (
                    <>
                    <div key={index} className="day-column">
                    <div key={index} className="day-header">{day}</div>
                        {renderTimeSlots(day, events)}
                    </div>
                    </>
                ))}
            </div>
        </div>
        {contextHolder}
        
        </>
    );
}

export default Calendar;