
import React, { useState } from 'react';
import md5 from 'crypto-js/md5';
import { Input } from 'antd';
import '../App.css';
import { getConfig } from './config';

function generateMD5(input) {
    // Using MD5 from crypto-js library
    const md5Hash = md5(input);
    const md5HexString = md5Hash.toString();
  
    return md5HexString;
  }

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function Login({currentActivity, setCurrentActivity, setUserHash, loginPromptText, onUserUpdate}) {

    const [loginName, setLoginName] = useState("");
    const [password, setPassword] = useState("");
    const [loginPrompt, setLoginPrompt] = useState(loginPromptText);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    const registerNew = () => {
        setCurrentActivity("REGISTER");       
    }

    const forgotPassword = () => {
        setCurrentActivity("FORGOTPASSWORD");
    }

    const handleLoginNameChange = (event) => {
        setLoginName(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser();
    };


    const loginUser = () => {
        
        setIsLoadingLogin(true);

        var loginPassword=generateMD5(password);
    
        var jsonData = {
            action: 'login',
            userName: loginName,
            passwordHash: loginPassword,
        };
    
        console.log(JSON.stringify(jsonData));
            
        fetch(getConfig('CM_API_URL'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonData),
        })
        .then(response => response.json()) 
        .then(responseData => {
    
           console.log(responseData);
    
            if (responseData['status']==='SUCCESS') {
    
                setCurrentActivity("SORTED");
                setLoginName('');
                setPassword('');
                setUserHash(responseData['message']); 
                setCookie("userHash", responseData['message'], 1);
                onUserUpdate(responseData['user']);

            } else {
                // login must have failed
                setLoginName('');
                setPassword('');
                document.getElementById('loginEmail').focus();
                setLoginPrompt(responseData['message']);
                setCookie("userHash", '', 1)
    
            }})
            .finally(() => {
                setIsLoadingLogin(false);
            });
    
    
    
    } // loginUser

    return (
        <>

            
                <div className="login-box" id="loginBox">
                    <div className="login-form">
                    {isLoadingLogin && (
                    <div className="wait-image">Logging in...<img src="./assets/wait.gif" alt="Please Wait..." /></div>
                )}
                        <div id="loginPrompt" className="login-prompt">{loginPrompt}</div>
                        <Input id="loginEmail" value={loginName} type="text" placeholder="Username" onChange={handleLoginNameChange}/>
                        <Input id="loginPassword" value={password} type="password" placeholder="Password" onChange={handlePasswordChange}/>
                        <button id="loginButton" className="custom-antd-button" onClick={handleSubmit}>Login</button>
                    </div>
                        <span id="forgotPassword" onClick={forgotPassword} className="login-link-text">Forgot Password?</span>&nbsp;&nbsp;
                        <span id="registerNew" onClick={registerNew} className="login-link-text">Register</span>
                </div>
  
        </>
    )

}

export default Login;