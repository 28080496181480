import React, { useState } from 'react';
import { DatePicker } from 'antd';

function DayPicker({onValueChange}) {

  const [value, setValue] = useState(null);

  const handleChange = (newValue) => {
    setValue(newValue); 
    if (onValueChange) {
      onValueChange(newValue); 
    }
  };
  return <DatePicker value={value} onChange={handleChange} />;
  
};

export default DayPicker;